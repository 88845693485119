@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  background-color: #000; /* Cambia el color de los puntos */
}

.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Cambia el color de las flechas */
}

.swiper {
  padding-bottom: 30px; /* Espacio para la paginación */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
